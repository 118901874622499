let blocks = [
  {
    id: 1,
    name: "Slider",
    file: "slider",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M8.17 3A3.001 3.001 0 0 1 11 1h2c1.306 0 2.417.835 2.83 2H21a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h5.17zM4 5v14h16V5h-4.17A3.001 3.001 0 0 1 13 7h-2a3.001 3.001 0 0 1-2.83-2H4zm7-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2h-2zm-1 6l5 3-5 3V9z"/></svg>',
    styles: [
      {
        selector: ".slider",
        name: "Go under the top element",
        class: "f-slider",
      },
      {
        name: "Height",
        selector: ".slider",
        classes: {
          "h-100": "Full height",
          "h-560": "Middle height",
          "h-320": "Small height",
        },
      },
      {
        name: "Text position",
        selector: ".slider",
        classes: {
          "text-under": "Under the image",
          "text-bottom": "Bottom",
          "text-center": "Center",
        },
      },
    ],
    content: [
      {
        name: "Slides",
        selector: ".slides",
        blocks: [
          {
            id: 101,
            name: "Slide",
            file: "slide",
            content: [
              {
                name: "Slides",
                selector: ".slide",
                values: [
                  {
                    name: "Title",
                    type: "text",
                    selector: "h2",
                    translates: true,
                  },
                  {
                    name: "Text",
                    type: "text",
                    selector: ".fs-5",
                    translates: true,
                  },
                  {
                    name: "Portrait image",
                    type: "image",
                    image: "cover",
                    selector: ".img-cover .portrait",
                    sizes: function () {
                      return "100vw";
                    },
                  },
                  {
                    name: "Landscape image",
                    type: "image",
                    image: "cover",
                    selector: ".img-cover .landscape",
                    sizes: function () {
                      return "100vw";
                    },
                  },
                  {
                    name: "Button text",
                    type: "text",
                    selector: "a",
                    translates: true,
                  },
                  {
                    name: "Link",
                    type: "link",
                    selector: "a",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Categories",
    file: "categories",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M11 4h10v2H11V4zm0 4h6v2h-6V8zm0 6h10v2H11v-2zm0 4h6v2h-6v-2zM3 4h6v6H3V4zm2 2v2h2V6H5zm-2 8h6v6H3v-6zm2 2v2h2v-2H5z"/></svg>',
    styles: [
      {
        selector: ".container > *",
        name: "No horizontal whitespace",
        class: "no-container",
      },
    ],
    content: [
      {
        name: "Categories",
        selector: ".scroll-x .pure-g",
        blocks: [
          {
            id: 102,
            name: "Category",
            file: "big-category-card",
            content: [
              {
                name: "Categories",
                selector: ".card-4",
                values: [
                  {
                    name: "Link",
                    type: "link",
                    selector: ".card-4 a",
                  },
                  {
                    name: "Title",
                    type: "text",
                    selector: ".btn-primary",
                    translates: true,
                  },
                  {
                    name: "Background",
                    type: "image",
                    image: "category",
                    selector: "img",
                    sizes: function () {
                      return "25vw";
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 15,
    name: "Category slider",
    file: "categorySlider",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M11 4h10v2H11V4zm0 4h6v2h-6V8zm0 6h10v2H11v-2zm0 4h6v2h-6v-2zM3 4h6v6H3V4zm2 2v2h2V6H5zm-2 8h6v6H3v-6zm2 2v2h2v-2H5z"/></svg>',
    content: [
      {
        name: "Categories",
        selector: ".scroll-x .sub-menu",
        blocks: [
          {
            id: 115,
            name: "Category",
            file: "categorySliderItem",
            content: [
              {
                name: "Categories",
                selector: "a",
                values: [
                  {
                    name: "Link",
                    type: "link",
                    selector: "a",
                  },
                  {
                    name: "Title",
                    type: "text",
                    selector: "span",
                    translates: true,
                  },
                  {
                    name: "Background",
                    type: "image",
                    image: "category",
                    selector: "img",
                    sizes: function () {
                      return "240px";
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Images",
    file: "images",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"/></svg>',
    styles: [
      {
        selector: ".container > *",
        name: "No horizontal whitespace",
        class: "no-container",
      },
      {
        name: "Images on a line",
        selector: ".pure-g > div",
        classes: {
          "pure-u-md-1-1": "1",
          "pure-u-md-1-2": "2",
          "pure-u-md-1-3": "3",
          "pure-u-md-1-4": "4",
          "pure-u-md-1-5": "5",
        },
      },
      {
        name: "Horizontal whitespace",
        selector: ".pure-g > div",
        classes: {
          "px-1": "1x",
          "px-2": "2x",
          "px-3": "3x",
          "px-4": "4x",
          "px-5": "5x",
        },
      },
    ],
    content: [
      {
        name: "Images",
        selector: ".pure-g",
        blocks: [
          {
            id: 103,
            name: "Images",
            file: "image",
            content: [
              {
                name: "Images",
                selector: "div",
                values: [
                  {
                    name: "Image",
                    type: "image",
                    image: "category",
                    selector: ".img-cover",
                    sizes: function () {
                      return "(max-width: 48em) 100vw,50vw";
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 4,
    name: "Top product",
    file: "topProduct",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M11 5H5v14h6V5zm2 0v14h6V5h-6zM4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z"/></svg>',
    styles: [
      {
        selector: ".container > *",
        name: "No horizontal whitespace",
        class: "no-container",
      },
    ],
    content: [
      {
        name: "Image",
        addMultiple: false,
        selector: ".d-sticky",
        values: [
          {
            name: "Image",
            type: "image",
            image: "category",
            selector: ".d-sticky img",
            sizes: function () {
              return "(max-width: 48em) 100vw,50vw";
            },
          },
        ],
      },
      {
        name: "Products",
        selector: ".products",
        blocks: [
          {
            id: 102,
            name: "Products",
            file: "product",
            content: [
              {
                name: "Products",
                selector: ".product",
                values: [
                  {
                    name: "Product",
                    type: "product",
                    selector: ".products .product",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 5,
    name: "Video",
    file: "video",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM4 5v14h16V5H4zm6.622 3.415l4.879 3.252a.4.4 0 0 1 0 .666l-4.88 3.252a.4.4 0 0 1-.621-.332V8.747a.4.4 0 0 1 .622-.332z"/></svg>',
    styles: [
      {
        selector: ".container > *",
        name: "No horizontal whitespace",
        class: "no-container",
      },
    ],
    content: [
      {
        name: "Video",
        addMultiple: false,
        selector: ".video-container",
        values: [
          {
            name: "Video",
            type: "video",
            selector: ".video-container",
          },
        ],
      },
    ],
  },
  {
    id: 7,
    name: "Text",
    file: "text",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M13 6v15h-2V6H5V4h14v2z"/></svg>',
    content: [
      {
        name: "Content",
        addMultiple: false,
        selector: "div",
        values: [
          {
            name: "Text",
            type: "rich-text",
            selector: "div",
            translates: true,
          },
        ],
      },
    ],
  },
  {
    id: 17,
    name: "HTML",
    file: "text",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M24 12l-5.657 5.657-1.414-1.414L21.172 12l-4.243-4.243 1.414-1.414L24 12zM2.828 12l4.243 4.243-1.414 1.414L0 12l5.657-5.657L7.07 7.757 2.828 12zm6.96 9H7.66l6.552-18h2.128L9.788 21z"/></svg>',
    styles: [
      {
        selector: ".container > *",
        name: "No horizontal whitespace",
        class: "no-container",
      },
    ],
    content: [
      {
        name: "Content",
        addMultiple: false,
        selector: "div",
        values: [
          {
            name: "Text",
            type: "long-text",
            selector: "div",
          },
        ],
      },
    ],
  },
 /* {
    id: 8,
    name: "Banner",
    file: "",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 3a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18zM4 10v9h16v-9H4zm0-2h16V5H4v3z"/></svg>',
  },
  {
    id: 9,
    name: "Map",
    file: "",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M4 6.143v12.824l5.065-2.17 6 3L20 17.68V4.857l1.303-.558a.5.5 0 0 1 .697.46V19l-7 3-6-3-6.303 2.701a.5.5 0 0 1-.697-.46V7l2-.857zm12.243 5.1L12 15.485l-4.243-4.242a6 6 0 1 1 8.486 0zM12 12.657l2.828-2.829a4 4 0 1 0-5.656 0L12 12.657z"/></svg>',
  },*/
  {
    id: 10,
    name: "Store info",
    file: "store",
    dynamic: "common.store",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 11.646V21a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-9.354A3.985 3.985 0 0 1 2 9V3a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v6c0 1.014-.378 1.94-1 2.646zm-2 1.228a4.007 4.007 0 0 1-4-1.228A3.99 3.99 0 0 1 12 13a3.99 3.99 0 0 1-3-1.354 3.99 3.99 0 0 1-4 1.228V20h14v-7.126zM14 9a1 1 0 0 1 2 0 2 2 0 1 0 4 0V4H4v5a2 2 0 1 0 4 0 1 1 0 1 1 2 0 2 2 0 1 0 4 0z"/></svg>',
  },
  {
    id: 11,
    name: "Image with text",
    file: "imageText",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"/></svg>',
    styles: [
      {
        selector: ".container > *",
        name: "No horizontal whitespace",
        class: "no-container",
      },
    ],
    content: [
      {
        name: "Image",
        addMultiple: false,
        selector: ".d-sticky",
        values: [
          {
            name: "Image",
            type: "image",
            image: "category",
            selector: ".d-sticky img",
            sizes: function () {
              return "(max-width: 48em) 100vw,50vw";
            },
          },
        ],
      },
      {
        name: "Content",
        addMultiple: false,
        selector: ".text",
        styles: [
          {
            selector: ".container > *",
            name: "No horizontal whitespace",
            class: "no-container",
          },
        ],
        values: [
          {
            name: "Text",
            type: "rich-text",
            selector: ".text",
            translates: true,
          },
        ],
      },
    ],
  },
  {
    id: 12,
    name: "Product slider",
    file: "productSlider",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0H24V24H0z"/><path d="M20 3c.552 0 1 .448 1 1v16c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h16zm-9 2H5v14h6v-4h2v4h6V5h-6v4h-2V5zm4 4l3 3-3 3v-2H9v2l-3-3 3-3v2h6V9z"/></svg>',
    styles: [
      {
        selector: ".container > *",
        name: "No horizontal whitespace",
        class: "no-container",
      },
    ],
    content: [
      {
        name: "Products",
        selector: ".products",
        blocks: [
          {
            id: 102,
            name: "Products",
            file: "product",
            content: [
              {
                name: "Products",
                selector: ".product",
                values: [
                  {
                    name: "Product",
                    type: "product",
                    selector: ".product",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 13,
    name: "Product list",
    file: "productList",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M14 10v4h-4v-4h4zm2 0h5v4h-5v-4zm-2 11h-4v-5h4v5zm2 0v-5h5v4a1 1 0 0 1-1 1h-4zM14 3v5h-4V3h4zm2 0h4a1 1 0 0 1 1 1v4h-5V3zm-8 7v4H3v-4h5zm0 11H4a1 1 0 0 1-1-1v-4h5v5zM8 3v5H3V4a1 1 0 0 1 1-1h4z"/></svg>',
    styles: [
      {
        selector: ".container > *",
        name: "No horizontal whitespace",
        class: "no-container",
      },
    ],
    content: [
      {
        name: "Products",
        selector: ".products",
        blocks: [
          {
            id: 102,
            name: "Products",
            file: "product",
            content: [
              {
                name: "Products",
                selector: ".products",
                values: [
                  {
                    name: "Product",
                    type: "product",
                    selector: ".products .product",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 14,
    name: "Header",
    file: "header",
    dynamic: "common.header",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 3a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18zM4 10v9h16v-9H4zm0-2h16V5H4v3z"/></svg>',
  },
  {
    id: 6,
    name: "Footer",
    file: "footer",
    dynamic: "common.footer",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 3a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18zM4 16v3h16v-3H4zm0-2h16V5H4v9z"/></svg>',
  },
];

export default blocks;
